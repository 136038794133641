.page-template-marcas {
    .boton {
        margin-bottom: 100px;
        margin-top: 40px;
    }

    #portfolio {
        .contenedor {
            max-width: 440px !important;
            text-align: left;
            margin: 0 auto
        }

        h1 {
            margin: 0px;
            font-size: 30px
        }
        
        h2 {
            font-size: 18px;
        }
        
        h1,
        h2 {
            color: $blanco
        }

        .heading h1::after {
            margin: 20px 0 40px;
            background-color:$verde2!important;
        }

        .boton {
            display: inline !important;
            background-color: rgba(255, 255, 255, 0.6);
            border: none;
            color: #212121 !important
        }
        
        &.portfolio-2 {
            margin: 0 -40px -40px 0;
            .portfolio-item {
                padding: 0 40px 40px 0;
            }
        }

    }
}

.mfp-iframe-holder .mfp-content { max-width: 1200px !important}
.mfp-bg { opacity: 0.9 !important}

@media(max-width:1359px) {
    .page-template-marcas {
        #portfolio {
            h1 {
                margin: 0px;
                font-size: 26px
            }

            .heading h2 {
                font-size: 17px;
            }
            
            &.portfolio-2 {
                margin: 0 -30px -30px 0;
                .portfolio-item {
                    padding: 0 30px 30px 0;
                }
            }
        }
    }
}

@media(max-width:1199px) {
    .page-template-marcas {
        h1 {
            margin-bottom: 100px;
        }
        #portfolio {
            h1 {
                margin: 0px;
                font-size: 23px
            }

            .heading h2 {
                font-size: 16px;
            }

            &.portfolio-2 {
                margin: 0 -20px -20px 0;
                .portfolio-item {
                    padding: 0 20px 20px 0;
                }
            }
            .contenedor {
                max-width: 360px !important;
            }
        }
    }
}

@media(max-width:991px) {
    .page-template-marcas {
        h1 {
            margin-bottom: 80px;
        }
        #portfolio {
            h1 {
                margin: 0px;
                font-size: 22px
            }

            &.portfolio-2 {
                max-width: 580px;
                margin: 0 auto -20px auto;
                .portfolio-item {
                    padding: 0 0px 20px 0;
                    width: 100%;
                }
            }
            .contenedor {
                max-width: 440px !important;
                text-align: center;
            }
            .heading h1::after { margin: 20px auto 40px auto !important}
        }
    }
}

@media(max-width:767px) {
    .page-template-marcas {
        #portfolio {
            h2 {
                font-size: 15.5px;
            }
            h1 {
                font-size: 21px
            }
        }
    }
}

@media(max-width:575px) {
    .page-template-marcas {
        #portfolio {
            h2 {
                font-size: 15px;
            }
            h1 {
                font-size: 20px;
                &:after {
                    margin: 20px auto 40px !important;
                }
            }
            .contenedor {
                max-width: 90% !important;
                text-align: center;
            }
        }
    }
}