#portfolio.portfolio-prensa {
    max-width: 1100px;
    margin: auto !important;
    .portfolio-item {
        padding: 0 20px 20px 0
    }
}

.single-prensas {
    .heading.center {margin-bottom:80px;}
    .contenedor-central {max-width:1000px;margin:auto;
        .row.clearfix {margin-bottom:80px;}
      p {font-family:"Libre Baskerville", serif!important;font-size:12px;line-height:28px!important;max-width:600px;} 
        img {width:400px;}
        }
    .col-md-6.alt img {float:right!important;}
    .heading h2 {margin-bottom:15px;}
    .heading h1{max-width:780px;margin:auto;}  
    .testimonio p {color:#8ca303!important;font-style:italic;letter-spacing:1px;font-size:16px;max-width:540px;line-height:28px!important;}
    
}

@media (max-width:991px){
    .page-template-prensa  {
        .portfolio-item {width:100%!important;}
    }
    .single-prensas {
    .contenedor-central {
        .row.clearfix {margin-bottom:10px;}
      p {font-family:"Libre Baskerville", serif!important;font-size:12px;line-height:28px!important;max-width:435px;} 
        img {width:100%;margin-bottom:40px;}
        }
    .col-md-6.alt img {float:unset!important;}
    
}
    
}