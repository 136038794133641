#slider {
    .slider-caption {  margin-top: -120px !important}

    .slider-caption h2 {
        font-weight: 500;
        font-size: 40px;
        color: #fff;
    }
    .slider-caption h2::after {

        content: "";
        width: 40px;
        height: 4px;
        background-color: $cafe;
        margin: 30px auto 36px;
        display: block;

    }

    .slider-caption p {
        font-size: 14px;
        font-family: $bask;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.2px;
        margin: 14px 0px 40px 0px;
        color: #fff;
    }
    span {
        color: #AF7661;
        margin-top: 12px
    }
    .one-page-arrow {
        bottom: 50px;
        img {
            width: 26px;
        }

    }
    .boton {
        border: 1px solid #fff;
        color: #fff !important
    }
    .slider-caption h2::after {
        background-color: #fff
    }
}





#home_foto.calidad { padding: 205px 0px;}
#home_foto {
    padding: 240px 0px;
    background-position: center; 
    h3 {
        color: $blanco;
        font-family: $brandon;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 28px
    }
    h3::after {

        content: "";
        width: 60px;
        height: 3px;
        background-color: $blanco;
        margin: 18px auto 0px;
        display: block;

    }
}

#home_rq {
    img { width: 240px; margin-bottom: 6px}
   
}

.home {
    .bajada1 { margin-bottom: 40px !important}
    
}

#prensa_listado {
    margin-bottom: 60px;
    article {
        max-width: 500px;
        height: 500px;
        margin: 0 auto;
    }
}

@media(max-width:1199px){
    #slider .slider-caption h2 {
        font-size: 36px;
    }
    
    #home_foto h3{
        font-size: 24px;
    }
    
    #home_rq img {
        width: 210px;
    }
}

@media(max-width:991px){
    #slider .slider-caption h2 {
        font-size: 32px;
    }
    
    #home_foto h3{
        font-size: 21px;
    }
    
    #home_rq img {
        width: 200px;
    }
    
    #home_foto {
        padding: 200px 0;
    }
}

@media(max-width:767px){
    body .swiper_wrapper:not(.force-full-screen), 
    body .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
        height: 700px !important;
    }
    
    #slider .slider-caption h2 {
        font-size: 30px;
    }
    
    #home_foto h3{
        font-size: 20px;
    }
    
    #home_rq img {
        width: 190px;
    }
    
    #home_foto.calidad {
        padding: 100px 0 60px;
        h3 {
            margin-bottom: 40px;
        }
    }
    #home_foto {
        padding: 180px 0;
    }
}

@media(max-width:575px){
    body .swiper_wrapper:not(.force-full-screen), 
    body .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
        height: 600px !important;
    }
    
    #slider .slider-caption h2 {
        font-size: 26px;
    }
    
    #home_foto h3{
        font-size: 18px;
    }
    
    #home_rq img {
        width: 160px;
    }
    
    #home_foto.calidad {
        padding: 100px 0 70px;
        h3 {
            margin-bottom: 30px;
        }
    }
    #home_foto {
        padding: 150px 0;
        background-size: cover;
    }
}


@media(max-height:850px) {
    #slider .slider-caption {
        margin-top: -80px !important
    }

}

@media(max-height:700px) {
    #slider .slider-caption {
        margin-top: 0px !important
    }

}