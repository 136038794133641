#seccion-texto .container {
    max-width: 560px;
}
#valles {
    .col1 {
        max-width: 500px;
        margin: 0 auto
    }
    .col-padding {
        padding: 120px 60px
    }
    .heading {
        h2 {
            margin-bottom: 6px
        }
        h1 {
            line-height: 46px
        }
        h1::after {
            margin: 30px 0 44px
        }
        span {
            color: $verde1
        }


    }
    .bajada1 {
        max-width: 460px
    }
}

#valles.produccion { 
    h1 {
        font-size: 38px;
        letter-spacing: 1px;
        line-height: 44px
    }

    .col1 {
        max-width: 550px
    }

    ul {
        margin-left: 13px;

        li {
            font-size: 13px;
            font-family: $bask;
            color: $morado;
            line-height: 20px;
            margin-bottom: 16px
        }

    }

}

#valles_listado {
    background: #000;

    .owl-stage {
        padding: 0px
    }

    .portfolio-overlay {
        opacity: 1 !important;
        background-color: transparent !important
    }

    h4 {
        color: $blanco;
        letter-spacing: 2px;
        margin: 0px
    }

    h4::after {
        display: none
    }

}

@media(max-width:1359px) {
    #valles .heading h1 {
        line-height: 37px;
    }
    #valles.produccion h1 {
        font-size: 36px;
        line-height: 41px;
    }
}

@media(max-width:1199px) {
    #valles .heading h1 {
        line-height: 41px;
    }
    #valles.produccion h1 {
        font-size: 32px;
        line-height: 38px;
        max-width: 440px;
    }
}
@media(max-width:991px) {
    body .heading h1::after {margin: 30px auto 44px auto !important }
    #valles.produccion h1 { max-width: 100% !important}
    #valles {
        text-align: center;
        .col1 {
            max-width: 460px;
        }
        .col-padding {
            padding: 80px;
            &.columna-foto {
                height: 380px !important;
                background-position: center -300px !important;
            }
        }
        &.produccion .col-padding.columna-foto {
            background-position: center center !important;
        }
        
        &.produccion {
            .col1 {
                max-width: 460px;
            }
            h1 {
                font-size: 30px;
                line-height: 36px;
                max-width: 400px;
            }   
        } 
    }
}
@media(max-width:767px) {
    
    #seccion-texto .container {
        max-width: 520px;
    }
    
    #valles {
        .heading h1 {
            line-height: 38px;
        }
        .col1 {
            max-width: 400px;
        }
        .col-padding {
            padding: 80px;
            &.columna-foto {
                height: 300px !important;
                background-position: center center !important;
            }
        }
        
        &.produccion {
            .col1 {
                max-width: 400px;
            }
            h1 {
                font-size: 27px;
                line-height: 33px;
                max-width: 360px;
            }   
        } 
    }
    
}
@media(max-width:575px) {
    
    #valles {
        .heading h1 {
            line-height: 35px;
        }
        .col-padding {
            padding: 60px;
            &.columna-foto {
                height: 260px !important;
            }
        }
        
        &.produccion {
            h1 {
                font-size: 25px;
                line-height: 31px;
                max-width: 300px;
            }   
        } 
    }
}

