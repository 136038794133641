.wpml-ls-sub-menu {background-color:#fff!important;}
#familia {
    .col-padding { padding: 120px 60px}
    .heading { max-width: 200px} 
    .heading h1::after { margin: 30px 0 44px}
    .bajada1 { max-width: 460px}
}


#historia {
    .owl-stage { padding-top: 0px}
    #historia-slider {
        span {color:$verde1!important;}
        margin-top: 0px;
        max-height: 500px;
        padding-bottom: 80px;
        padding-left: 70px;
        padding-right: 70px;
        .owl-item{
            padding-left: 35px;
            &:after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: $verde1;
                display: block;
                position: absolute;
                bottom: 86px;
                left: 45px;
            }
            .varios {
                .elemento-chico,
                .elemento-alto {
                    display: inline-block;
                    max-width: 200px;
                }
            }
        }
        .elemento-chico {
            height: 160px;
            width: 100%;
            .linea {
                height: 155px;
            }
            &.abajo {
                position: relative;
                margin-top: 100px;
            }
        }
        .elemento-alto {
            height: 160px;
            position: relative;
            top: -41px;
            margin-bottom: -140px;
            left: 20px;
            .linea {
                height: 155px;
                &:after {
                    display: none;
                }
            }
            .texto-hito .opa {
               opacity: 0;
            }
            .texto-hito {
                width: 200px;
            }
        }
        .linea {
            width: 1px;
            background-color: $verde1;
            display: inline-block;
            float: left;
            position: relative;
            top: -5px;
            margin-right: 20px;
            margin-left: 7px;
            &:before {
                content: "";
                width: 7px;
                height: 7px;
                background-color: $verde1;
                border-radius: 50%;
                position: absolute;
                top: 0px;
                left: -3px;
                display: block;
            }
            &:after {
                content: "";
                width: 7px;
                height: 7px;
                background-color: $verde1;
                border-radius: 50%;
                position: absolute;
                bottom: 0px;
                left: -3px;
                display: block;
            }
        } 
        .titulo-hito{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 15px;
            margin-bottom: 5px;
            letter-spacing: 1px;
            max-width:200px;
        }
        .texto-hito{
            font-family: $bask;
            font-size: 11px;
            line-height: 19px;
            max-width:200px;
        }
        .anio {
            font-family: $bask;
            font-style: italic;
            font-size: 30px;
            letter-spacing: 2px;
            font-weight: lighter;
            display: block;
            position: relative;
            left: -30px;
            margin-top: 40px;
        }
        .owl-nav [class*="owl-"] {
            width: auto;
            opacity: 1;
            background-color: transparent !important;
            color: $verde1 !important;
            font-family: $bask;
            top: 378px;
            span {
                margin: 0 7px;
                font-size: 46px;
            }
        }
        .owl-nav .owl-prev, .owl-nav .owl-next { top: 40% !important;}
        .owl-dots .owl-dot {
            background-color: transparent;
            border: 0;
            padding: 0;
            span {
                border-color: $verde1;
            }
            &.active span {
                background-color: $verde1 !important;
            }
        }
    }
}

#oficinas {
    padding: 90px 0px 0px 0px;
    background: #212121;

    h1,
    h2,
    .bajada1 {
        color: $blanco
    }
    
    .bajada1 {
        margin-bottom: 80px;
    }

}

#mercados {
    background: #fff;
    .continente {
        width: 120px;
        margin-bottom: 20px
    }

    #c2 {
        margin-top: 40px;

        h3 {
            letter-spacing: 1.4px;
        }

        ul li {
            list-style: none;
            font-family: $bask;
            font-size: 12.4px;
            line-height: 28px
        }


    }

    label {
        display: none
    }
    input,
    textarea {

        width: 100%;
        background-color: transparent;
        border: 0;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: 0px;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: 0px;
        border-bottom: 1px solid #715B49;
        padding: 6px 0px;
        max-height: 100px;
        resize: none;
        color: #413445;
        letter-spacing: 0.4px;
        font-weight: normal;
        font-size: 12px;
        opacity: 1;

    }
    .validation_error {
        font-family: 'Libre Baskerville', serif;
        font-size: 13px !important;
        color: #AF7661;
        
    }

    ul li {
        list-style: none;
        margin-bottom: 30px;

    }
    .gform_button {
        font-family: 'Libre Baskerville', serif;
        font-weight: 400;
        font-style: italic;
        color: #AF7661 !important;
        font-size: 13px !important;
        border: 1px solid #AF7661;
        padding: 10px 24px;
        border-radius: 25px;
        display: inline-block;
        text-transform: none;
        letter-spacing: 0px;
        width: 120px;
        margin: 0 auto;
        text-shadow: none !important;
        line-height: 14px;
        height: auto;
    }
    .gfield_description {

        text-align: left !important;
        font-size: 11px !important;
        position: relative;
        top: 2px;
        height: 10px;
    }



}


#equipo { padding-bottom:20px}

#listado_equipo {
    img { display: none}
    .tab-content .col-lg-3 {margin: 0 auto}
    ul.tab-nav {
        margin-top: 90px;
        margin-bottom: 20px;
        border: none;
        text-align: center;
        li {
            border: none !important;
            float: none;
            display: inline-block;
            &:first-child {
                margin-left: 0;
            }
        }
        li a {
            font-weight: 500;
            text-transform: uppercase;
            font-family: $brandon;
            color: #959595;
            font-size: 18px;
            letter-spacing: 1px;
            padding: 0px 40px;
            border-right: 2px solid $verde1;
            height: 22px;
            line-height: 22px
        }
        li.ui-tabs-active a {
            background: none;
            color: $morado;
            top: 0px;
        }
        li:last-child a {
            border: none
        }

    }
    img {
        width: 200px;
        margin-bottom: 14px
    }
}


.cirulo {
    width: 170px;
    height: 170px;
    background: $verde2;
    border-radius: 170px;
    line-height: 170px;
    color: $blanco;
    font-family: $bask;
    font-size: 56px;
    margin: 80px auto 30px auto;
    span { position: relative; left: 8px}
        
}

#ranking {
    background: #212121;
    h1,
    h2,
    h4,
    .bajada1 {
        color: $blanco
    }
    h4::after {
        display: none;
    }
    h4 {
        margin-bottom: 0px;
        letter-spacing: 1px;
    }
}

#ranking2 {
    .heading h1 { line-height: 42px; margin-bottom: 14px}
    .col-padding {
        padding: 150px 130px
    }
    .cirulo {
        position: absolute;
        right: -87px;
        z-index: 99;
        top: 40px;
        text-align: center;
        span { left: 4px }
    }
    .col1 {
        max-width: 280px;
        margin: 0 auto;
        h1::after {
            display: none
        }
        h2::after {

            content: "";
            width: 40px;
            height: 4px;
            background-color: $verde1;
            margin: 40px 0px 0px;
            display: block;

        }
    }
    #col2 {
        max-width: 440px;
        h1, h2 { color: $blanco}
        h1 { font-size: 34px; line-height: 42px}
        h2 { margin-top: 12px;}
        h2::after { display: none}
    }
}


body .mpfy-p-popup-style-two {
    .mpfy-p-top {
        padding: 20px 80px 20px 17px;
        background-color: transparent;
        h1 {
            display: none;
        }
        &:before {
            display: none;
        }
    }
    .mpfy-p-close span::after {
        background: #ccc;
        width: 20px;
        height: 2px;
        left: 0;
        top: 7px;
    }
    .mpfy-p-close span::before{
        background: #ccc;
        width: 2px;
        height: 20px;
        left: 9px;
        top: -2px;
    }
    .heading {
        h2 {
            font-size: 20px;
        }
    }
    p {
        font-family: $bask;
        padding-bottom: 0;
        color: $morado;
        font-size: 12px;
        line-height: 26px;
    }
    
    h4 {
        font-family: $bask;
        font-size: 10px;
        font-weight: bold;
        &:after {
            display: none;
        }
    }
    .imagenmapa {
        margin-top: 30px;
    }
    .mpfy-p-entry {
        float: none;
        margin: 0;
    }
}

.mpfy-tooltip {
  width: auto !important; }
  .mpfy-tooltip .center {
    padding: 10px 20px !important; }
    .mpfy-tooltip .center p {
      font-size: 12px !important;
      letter-spacing: 1px !important;
      color: $morado !important; }
      .mpfy-tooltip .center p strong {
        font-weight: lighter !important; }
  .mpfy-tooltip * {
    font-family: "Trajan Pro" !important; }


@media (min-width:1600px){
    #historia {
        #slider {
            margin: 0 auto;
        }
    }
}

@media (max-width:1599px){
    #historia {
        #slider {

            margin: 0 auto;
        }
    }
}

@media (max-width:1459px){
    #historia {
        #slider {
            margin: 0 auto;
        }
    }
}

@media (max-width:1359px){
    #historia {
        #slider {
            margin: 0 auto;
        }
    }
    

}

@media (max-width:1199px){
    #historia {
        #slider {
            margin: 0 auto;
        }
        #historia-slider {
            .anio {
                font-size: 24px;
                margin-top: 38px;
            }
            .owl-item::after {
                bottom: 74px;
            }
        } 
    }
    
    #cont-premios {
        max-width: 900px !important;
    }
    
    #equipo {
        #listado_equipo {
            max-width: 900px !important;
            ul.tab-nav li a {
                font-size: 16px;
                padding: 0 19px;
            }
            .tab-container h4 {
                font-size: 15px;
            }
        }
    }
    
    .cirulo {
        width: 160px;
        height: 160px;
        line-height: 160px;
        font-size: 52px;
    }
    
     #ranking2 #col2 h1 {
        font-size: 26px;
        line-height: 36px;
    }
}
@media (min-width:992px){ 
    #menu-item-225, #menu-item-262 { display: none}

}


@media (max-width:991px){
    #familia {
        text-align: center;
        .heading { margin: 0 auto !important}
        h1::after { margin: 30px auto 44px auto !important;}
        .col-padding {
            padding: 100px 60px;
            > div {
                max-width: 460px;
                margin: 0 auto;
            }    
        }
    }
    
    #historia {
        #historia-slider {
            padding-bottom: 0px;
            max-height: 480px;
            .owl-nav {
                display: none;
            }
        }
    }
    
    #mercados {
        #c2 h3 {
            font-size: 18px;
        }  
        .continente {
            width: 100px;
        }
    }
    
    #cont-premios {
        max-width: 700px !important;
    }
    
    #equipo {
        #listado_equipo {
            max-width: 700px !important;
            ul.tab-nav li a {
                font-size: 16px;
                padding: 0 19px;
            }
            .tab-container h4 {
                font-size: 15px;
            }
        }
    }
    
    .cirulo {
        width: 140px;
        height: 140px;
        line-height: 140px;
        font-size: 43px;
    }
    
    #ranking2 {
        .col-padding {
            padding: 100px;
        }   
        .cirulo {
            top: 220px;
            right: 50%;
            margin-right: -70px;
        }
        .col1 {
            .heading {
                text-align: center;
                h2:after {
                    margin: 40px auto;
                }
            }
        }
        #col2 h1 {
            font-size: 25px;
            line-height: 35px;
        }
    } 
    
}

@media (max-width:767px){
    
    #familia {
        .col-padding:first-child {
            height: 400px !important;
        }
    }
    
    #historia {
        #slider {
        }
    }
    
    #equipo {
        > .container {
            max-width: 460px !important;
        }
        #listado_equipo {
            max-width: 460px !important;
            ul.tab-nav li a {
                padding: 0 17px;
            }
        }
    }
    
    .cirulo {
        width: 130px;
        height: 130px;
        line-height: 130px;
        font-size: 38px;
    }
    #ranking > .container {
        max-width: 460px !important;
    }
    #ranking2 { 
        .cirulo {
            top: 160px;
            right: 50%;
            margin-right: -70px;
        }
        #col2 h1 {
            font-size: 24px;
            line-height: 34px;
        }
    } 
    
    body .mpfy-p-popup-style-two .heading {
        h1 {
            font-size: 24px;
            line-height: 27px;
        }
        h2 {
            font-size: 17px;
        }
    }
}

@media (max-width:575px){
    #familia {
        .col-padding:first-child {
            height: 340px !important;
        }
        .heading {
            max-width: none;
            text-align: center;
            h1:after {
                margin: 30px auto 44px;
            }
        }
        .bajada1 {
            text-align: center;
        }
    }
    
    #historia {
        #slider {
            max-width: 300px;
        }
        #historia-slider {
            max-height: 450px;
            .anio {
                font-size: 21px;
                margin-top: 32px;
            }
            .owl-item::after {
                bottom: 65px;
            }
        }
        
    }
    
    #listado_equipo ul.tab-nav li {
        width: 100%;
        a {
            border-right: 0;
        }
    }
    
    #ranking2 {
        #col2 h1 {
            font-size: 21px;
            line-height: 32px;
        }
        .col-padding:first-child {
            padding: 100px 0 70px;
        }
        .cirulo {
            top: 210px;
            margin-right: -60px;
        }
    }
    
    .cirulo {
        margin: 30px auto;
        font-size: 30px;
        width: 120px;
        height: 120px;
        line-height: 120px;
        span {
            left: 2px;
        }
    }
}
