/*-----------------------------------------------------------------------------------

    Theme Name: Canvas
    Theme URI: http://themes.semicolonweb.com/html/canvas
    Description: The Multi-Purpose Template
    Author: SemiColonWeb
    Author URI: http://themeforest.net/user/semicolonweb
    Version: 5.5

    LESS Stylesheet

-----------------------------------------------------------------------------------*/


// Initialize
@import "sass/variables.scss";
@import "sass/mixins.scss";


// Core CSS
@import "sass/typography.scss";
@import "sass/helpers.scss";
@import "sass/layouts.scss";


// Content Blocks
@import "sass/topbar.scss";
@import "sass/header.scss";
@import "sass/sliders.scss";
@import "sass/pagetitle.scss";
@import "sass/content.scss";
@import "sass/portfolio.scss";
@import "sass/blog.scss";
@import "sass/shop.scss";
@import "sass/events.scss";


// Shortcodes
@import "sass/shortcodes.scss";


// Footer
@import "sass/footer.scss";


// Widgets
@import "sass/widgets.scss";


// Extras
@import "sass/extras.scss";

// Sur Valles
@import "sass/survalles/globales.scss";
@import "sass/survalles/home.scss";
@import "sass/survalles/marcas.scss";
@import "sass/survalles/survalles.scss";
@import "sass/survalles/requingua.scss";
@import "sass/survalles/sustentabilidad.scss";
@import "sass/survalles/prensa.scss";