
@font-face {
    font-family: 'Brandon Grotesque';
    src: url('css/fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
        url('css/fonts/BrandonGrotesque-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('css/fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
        url('css/fonts/BrandonGrotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('css/fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
        url('css/fonts/BrandonGrotesque-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('css/fonts/BrandonGrotesque-Light.woff2') format('woff2'),
        url('css/fonts/BrandonGrotesque-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}


$cafe:#AF7661;
$morado:#413445;
$blanco: #F4F4F4;
$brandon:'Brandon Grotesque';
$bask:'Libre Baskerville', serif;
$verde1:#8ca303;
$verde2:#bddc04;

/* CONTAINER */

@media(min-width:1360px){
    #content .container, #header .container {
        max-width: 1300px;
    }
}

@media(min-width:1500px){
    #content .container, #header .container {
        max-width: 1440px;
    }
}

.overlay-menu #primary-menu > ul.d-block {
    overflow-y: auto;
    background: #fff;
}

.overlay-menu #primary-menu > ul > li > a {
    transition: none;
	}

.video-overlay, .video-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background: transparent url('images/grid.png') repeat;
        background-color: transparent;
    -webkit-backface-visibility: hidden;

}

/* HEADER */

#header {
    .container {
        height: 90px
    }
    #logo img {
        height: 66px;
        position: relative;
        top: 14px
    }
    .icon-line-menu {
        font-size: 19px;
    }
    span.txt {
        font-family: $brandon;
        text-transform: uppercase;
        font-weight: bold;
        padding-left: 10px;
        letter-spacing: 1px;
        font-size: 14.6px;
        position: relative;
        top: -3px;
        color: #413445;
    }

}

#primary-menu .current-menu-item a{ color: $verde2 !important}


  





/* LOGO OVERLAY*/

    #logo_overlay {
        z-index: 99999;
        position: absolute;
        top: 40px;
        left: 0px;
        opacity: 0 !important;
        img { height: 66px;}

    }
    body.primary-menu-open #logo_overlay {
        opacity: 1 !important;
        @include transition(opacity .7s .4s ease, transform .45s .15s ease);
        @include translateY(0);
    }

/* MENU OVERLAY */

@media(min-width:992px) {
    .overlay-menu {
        #primary-menu ul li > a span {
            display: block !important;
            font-family: bask;
            font-style: italic;
            font-size: 13px;
            letter-spacing: 0px;
            font-weight: 400;
            margin: 0px;
            text-transform: none;
            position: relative;
            top: 0px;
        }
        #primary-menu > ul.d-block > li > a {
            text-transform: uppercase;
            color: $morado;
            font-weight: 500;
            font-size: 24px;
            letter-spacing: 0.6px
        }
        #primary-menu > ul.d-block > li > a:hover {
            color: $verde1;
        }
        #primary-menu #lang_sel{
            display: none;
        }
    }
    
    /* FIX OVERLAY */
    .overlay-menu.primary-menu-open {
        overflow-y: hidden;
        #header-wrap {
            position: fixed;
            width: 100%;
            height: 100% !important
        }

    }
    
    #header #lang_sel.wpml-ls-legacy-dropdown {
        position: absolute;
        right: 260px;
        top: 32px;
        max-width: 110px;
        border: 0;
        a {
            border: 0;
            text-transform: uppercase;
            font-weight: bold;
            padding-left: 10px;
            letter-spacing: 1px;
            font-size: 14px;
            color: #413445;
            background-color: transparent;
        }
        .wpml-ls-current-language:hover > a,
        .wpml-ls-statics-shortcode_actions a:hover {
            background-color: transparent !important;
            color: #555 !important;
        }
        .wpml-ls-sub-menu {
            border: 0;
        }
    }
}

#contacto_menu {
        position: absolute;
        right: 140px;
        top: 35px;
        a {
            letter-spacing: 1px;
            font-family: "Brandon Grotesque";
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            color: #413445;
        }
    }


.boton {
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    font-style: italic;
    color: $verde1 !important;
    font-size: 13px !important;
    border: 1px solid $verde1;
    padding: 10px 24px;
    border-radius: 25px;
    display: inline-block;
}

.heading {
    h2 {
        font-family: $bask;
        color: $morado;
        font-style: italic;
        font-weight: 400;
        font-size: 21px;
        margin-bottom: 0px;
        line-height: 22px
    }
    h1 {
        font-weight: 500;
        letter-spacing: 1.4px;
        font-size: 39px;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: $morado;
    }
    h1::after {
        content: "";
        width: 40px;
        height: 4px;
        background-color: $verde1;
        margin: 28px auto 40px;
        display: block;

    }
    
}



h5 {
    color: $verde1;
    font-family: $bask;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
    margin-bottom: 6px
}

h3 {
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 20px;
    margin-bottom: 0px;
    text-transform: uppercase;
    color: #413445;
    line-height: 25px

}

h3::after {

    content: "";
    height: 4px;
    background-color: $verde1;
    margin: 16px auto 30px;
    display: block;
    width: 30px;




}

h4 {
    text-transform: uppercase;
    color: $morado;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 70px;
}

h4::after {

    content: "";
    width: 18px;
    height: 3px;
    background-color: $verde1;
    margin: 10px auto 0px;
    display: block;

}



#content .bajada1 {
    font-family: $bask;
    color: $morado;
    line-height: 26px;
    font-size: 13px;
    margin-bottom: 0px
}


article {
    .text-overlay {
        padding: 30px 40px;
    }
    .entry-meta{
        margin: 0;
        li {
            font-style: normal;
            text-transform: uppercase;
            font-size: 11px;
            letter-spacing: 1.5px;
            color: #FFF;
            &:before {
                content: "|";
            }
        }
    }
    .titulo-art {
        margin-bottom: 0;
        text-transform: none;
        font-weight: 300;
        font-family: $bask;
        font-size: 22px;
        &:after {
            display: none;
        }
    }
}

#footer {
    
    .ubicacion { width: 17px; margin-right: 2px; position: relative; top: -3px}
    .telefono { width: 15px; margin-right: 6px; position: relative; top: -2px}
    .email { width: 15px; margin-right: 6px; position: relative; top: -1px}
    
}



@media (max-width:1359px){
    .heading {
        h1 {
            font-size: 37px;
        }
    }
}

@media (max-width:1199px){
    .heading {
        h1 {
            font-size: 34px;
        }
        h2 {
            font-size: 19px;
        }
    }
}

@media(max-width:991px){
    #logo_overlay,
    #lang_sel {
        display: none;
    }
    
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul#menu-menu > li:hover a, 
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul#menu-menu > li.current a, 
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul#menu-menu > li:hover a,
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div#lang_sel > ul > li:hover a,
    body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul#menu-menu > li.current a {
        color: $morado !important;
    }
    
    body #header #primary-menu #lang_sel {
        display: block !important;
        width: 100%;
        span {
            display: block;
        }
        > ul {
            padding-top: 0 !important;
            border-top: 1px solid #eee !important;
            li {
                background-color: transparent !important;
            }
            li:hover a{
                background-color: transparent !important;
            }
            .wpml-ls-sub-menu{
                padding-left: 0;
                li a {
                    font-family: $brandon;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
            }
        }
        &.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
            right: auto;
            left: 80px;
        }
    }
    
    #header {
        #logo {
            height: 90px;
        }
        .container {
            height: auto;
        }
    }
    
    .heading {
        h1 {
            font-size: 32px;
        }
        h2 {
            font-size: 18px;
        }
    }
    
    footer .columna {
        text-align: center;
    }
    #contacto_menu { display: none}
}

@media(max-width:767px){
    .heading {
        h1 {
            font-size: 30px;
        }
        h2 {
            font-size: 17px;
        }
    }
}

@media(max-width:575px){
    #primary-menu-trigger .txt{
        display: none;
    }
    .heading {
        h1 {
            font-size: 27px;
        }
        h2 {
            font-size: 16px;
        }
    }
}


/* EN EL MUNDO LIGHTBOX */


.gm-fullscreen-control { display: none}
.gmap {
    margin-top: 68px;
}

.contacto a {
    font-family: "Fira Sans";
    font-size: 20px;
    font-weight: normal;
    color: #7a95bc;
    line-height: 40px
}

.mpfy-p-content,
.mpfy-p-content > .mpfy-p-holder {
    width: 100% !important;
}

.mpfy-p-entry {
    float: none !important;
}

.section.contacto {
    padding: 0px !important;
}

.contact .entradapagina {
    margin-top: 61px;
    margin-bottom: 58px;
}

.contact p {
    margin-bottom: 0px
}

.mpf-p-popup-holder .mpfy-p-top .mpfy-p-close span {
    margin: 20px -4px !important;
}

.mpf-p-popup-holder .mpfy-p-close span::before {
    width: 2px !important;
    height: 26px !important;
    left: 10px !important;
    top: -5px !important;
    width: 2px !important;
}

.mpf-p-popup-holder .mpfy-p-close span::after {
    height: 2px !important;
    left: -2px !important;
    top: 7px !important;
    width: 26px !important;
}

.mpf-p-popup-holder .mpfy-p-entry p {
    padding-bottom: 0px !important;
}

.mpf-p-popup-holder .mpfy-p-entry {
    width: 100% !important;
}

.mpf-p-popup-holder .mpfy-p-content {
    padding: 26px 0px 0px !important;
    margin-bottom: -44px !important;
}

.mpf-p-popup-holder .mpfy-p-popup {
    max-width: 830px !important;
    margin: 0 auto;
    position: relative;
    left: auto
}

.mpfy-p-popup > .mpfy-p-holder {
    border-radius: 0px !important;
}

.mpf-p-popup-holder p,
.mpf-p-popup-holder a {
    font-family: "Libre Baskerville", serif;
    font-size: 13px;
    color: #424d5f !important;
    line-height: 26px
}

.mpf-p-popup-holder .mpfy-p-color-header-background {
    background-color: #f3f3f3 !important;
}

.mpf-p-popup-holder .mpfy-p-holder.mpfy-p-color-popup-background {
    background-color: #f3f3f3 !important;
}

.mpf-p-popup-holder .contenidomodalcontacto h3 {
    font-family: "Lora" !important;
    letter-spacing: 2px;
    color: #1a213b;
    margin-bottom: 2px !important;
    font-size: 28px
}

.mpf-p-popup-holder .nombrecompaniamodal {
    font-family: "Fira Sans" !important;
    font-weight: 400;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #7490b5 !important;
    display: block;
    margin-bottom: 20px
}

.mpf-p-popup-holder .contenidomodalcontacto p {
    max-width: 530px;
    margin: 0 auto
}

.contact .mpfy-fullwrap {
    margin-bottom: 0px !important
}

.contenidomodalcontacto {
    margin-bottom: 40px !important;
    padding: 0px 20px !important
}

.mpfy-p-popup .mpfy-p-close span,
.mpfy-p-popup .mpfy-p-close:hover span {
    transition: none !important;
}

.mpfy-p-popup a,
.mpfy-p-popup a:hover,
.mpfy-p-comments input[type="submit"],
.mpfy-p-comments input[type="submit"]:hover,
.mpfy-p-links-holder a,
.mpfy-p-links-holder a:hover {
    transition: none !important;
}

.mpf-p-popup-holder .mpfy-p-popup a:hover {
    text-decoration: none !important;
}

.mpf-p-popup-holder .mpfy-p-close:hover span {
    transform: rotate(45deg) !important;
}

.mpfy-zoom-out {
    margin: 0 0 0 10px !important;
}



@media (max-width: 767px) {

.mpfy-p-top::before {
        background: #f3f3f3 !important
    }

    .mpfy-p-close span::before,
    .mpfy-p-close span::after {
        background: #535353 !important
    }
    .mpfy-p-social-small {
        display: none
    }
}

@media (max-width: 767px) { 
    .mpf-p-popup-holder .contenidomodalcontacto h3 {
        letter-spacing: 2px;
        line-height: 30px;
        margin-bottom: 20px !important
    }
    .contenidomodalcontacto {
        padding: 0px 20px !important
    }
    .mpf-p-popup-holder .nombrecompaniamodal {
        letter-spacing: 1px;
        font-size: 16px
    }
}








