#certificados {
    margin-top: 60px;
    .image1 {
        width: 220px;
        position: relative;
        top: 30px
    }
    .image2 {
        width: 120px
    }
    .image3 {
        width: 120px
    }

}

#descargas {
    .boton { padding: 4px 14px; font-size: 12px !important; position: relative; top: -2px} 
    
}

@media (max-width:991px){
    #descargas .col-lg-3 {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        } 
    }
    #descargas { padding: 90px 0px 30px 0px;}
    #descargas .boton { margin-bottom: 60px;}
    #certificados {
        max-width: 600px !important;
        .image1 {
            width: 200px;
        }
        .image2, .image3 {
            width: 110px;
        }
    }
}

@media (max-width:767px){
     #intro-sust {
        max-width: 500px !important;
    }
    #certificados {
        max-width: 500px !important;
        .image1 {
            width: 200px;
        }
        .image2, .image3 {
            width: 100px;
        }
    }
}

@media (max-width:767px){
     #intro-sust {
        max-width: 100% !important;
        padding: 0 40px;
    }
    #certificados {
        max-width: 100% !important;
        padding: 0 40px;
        .image1 {
            width: 180px;
            top: 0;
        }
        .image2, .image3 {
            width: 90px;
        }
        .col-sm-4 {
            margin-bottom: 30px;
            &:last-child { 
                margin-bottom: 0;
            }
        }
    }
}